import React from 'react';
import './style.scss';

const CheckList = ({
  name,
  type,
  options,
  onChange,
  value,
  disabled = false
}) => (
  <div className="check-list">
    {options.map(opt => (
      <label key={opt.value} className="check-item">
        <input
          name={`${name}_${opt.value}`}
          type="radio"
          value={opt.value}
          onChange={e => onChange(e)}
          checked={value === opt.value}
          disabled={disabled}
        />
        <span>{opt.name}</span>
      </label>
    ))}
  </div>
);

export default CheckList;
