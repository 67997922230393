import { openDB } from 'idb';

const DB_NAME = '@USEFLOW';
const DB_VERSION = 1;
const STORE_NAME = 'survey_responses';

const checkIndexedDBSupport = () => {
  return window.indexedDB;
};

export const initDB = async () => {
  return checkIndexedDBSupport()
    ? openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME, {
              keyPath: 'id',
              autoIncrement: false
            });
          }
        }
      })
    : null;
};

export const saveResponse = async response => {
  const db = await initDB();
  if (db) {
    await db.put(STORE_NAME, { id: response.id, data: response.data });
  }
};

export const getResponse = async id => {
  const db = await initDB();
  const response = db ? await db.get(STORE_NAME, id) : undefined;
  return response;
};

export const removeResponseById = async id => {
  const db = await initDB();
  if (db) {
    await db.delete(STORE_NAME, id);
  }
};

export const removeDatabase = async response => {
  window.indexedDB.deleteDatabase(DB_NAME);
};
